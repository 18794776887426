footer {
  background-color: #303030;
  color: #fff;
  padding: 50px 0;
  a {
    font-size: 20px;
    color: #fff;
    margin-right: 30px;
    transition: all 0.3s ease-in-out;
    &:hover {
      color: #5ac18e;
      text-decoration: none;
    }
  }
}
