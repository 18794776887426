#about {
  background-color: #f1f1f1;
  padding: 100px 0;
  img {
    max-height: 50px;
    margin: 15px;
  }
  h2 {
    margin-bottom: 30px;
  }
  p {
    text-align: center;
    max-width: 500px;
    margin: 0 auto;
    margin-bottom: 50px;
  }
}
