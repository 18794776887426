header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
}

.nav-link {
  cursor: pointer;
}
