html, body {
    display: block;
    min-height: 100%;
}

#root {
    width:100%;
    min-height:100%;
}

iframe {
    border: 0;
    width:100%;
    height:100vh;
    display: block;
}