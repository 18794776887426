.portfolio-item {
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  .portfolio-item-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 15px;
    transition: all 0.3s ease-in-out;
    h4 {
      color: #fff;
      transition: all 0.3s ease-in-out;
      font-size: 1rem;
      position: absolute;
      bottom: 0;
    }
  }
  &:hover {
    .portfolio-item-overlay {
      background-color: rgba(0, 0, 0, 0);
      h4 {
        transform: translateY(50px);
      }
    }
  }
  &.hide {
    display: none;
  }
}
