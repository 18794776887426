#hero {
  max-height: 70%;
  max-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  .heroOverlay {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
    .heroContent {
      top: 56px;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      max-width: 98%;
      height: 100%;
      margin: 0 auto;
      p {
        max-width: 90%;
        text-align: center;
        @media (min-width: 768px) {
          max-width: 600px;
        }
        @media (min-width: 991px) {
          max-width: 700px;
        }
      }
    }
  }
}
